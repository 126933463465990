<script setup lang="ts">
import { differenceInDays, formatDistanceToNowStrict, fromUnixTime } from 'date-fns';

const loading = ref(true);
const sidebarOpen = ref(false);

onMounted(async () => {
  if (userData.value.type === 'driver') {
    loading.value = true;
    try {
      await getAlerts();
      await getSettings();
    } finally {
      loading.value = false;
    }
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'start_pw', pw_user_email: userData.value.email });
});

const alertsStore = useAlertsStore();
const { getAlerts } = alertsStore;
const { alerts } = storeToRefs(alertsStore);

const { loggedIn } = storeToRefs(useAuthStore());

const userStore = useUserStore();
const { fullName, isOnFreePlan, isTrialing, trialEnd, userData } = storeToRefs(userStore);

const settingsStore = useSettingsStore();
const { getSettings } = settingsStore;
const { settings } = storeToRefs(settingsStore);

const daysLeftInTrialDisplay = computed(() => {
  return formatDistanceToNowStrict(fromUnixTime(trialEnd.value), { unit: 'day', roundingMethod: 'floor' });
});

const daysLeftInTrial = computed(() => differenceInDays(fromUnixTime(trialEnd.value), Date.now()));

const displayName = computed(() => fullName.value || userData.value.email);

const userHasCompany = computed(() => Boolean(userData.value.company?.length));

const allAlertsInactive = computed(() => alerts.value.length > 0 && alerts.value.every(alert => !alert.active));

const noAlertMethodsSet = computed(() => {
  return !settings.value.notifyByEmail && (!settings.value.notifyByText || !userData.value.isPhoneNumberVerified);
});

const closeSidebar = () => {
  if (sidebarOpen.value) {
    sidebarOpen.value = false;
  }
};

const route = useRoute();

watch(() => route.fullPath, () => closeSidebar());

watch(loggedIn, (val) => {
  if (!val) {
    navigateTo('/login');
  }
});
</script>

<template>
  <div class="flex h-screen overflow-hidden bg-gray-100">
    <skip-to />
    <!-- Off-canvas menu for mobile -->
    <div class="md:hidden">
      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity ease-linear duration-300"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <!-- Off-canvas menu overlay, show/hide based on off-canvas menu state. -->
        <div
          v-show="sidebarOpen"
          class="fixed inset-0 z-40 flex"
        >
          <div class="fixed inset-0">
            <div class="absolute inset-0 bg-gray-600 opacity-75" />
          </div>

          <!-- Off-canvas menu, show/hide based on off-canvas menu state. -->
          <transition
            enter-active-class="transition ease-in-out duration-300 transform"
            enter-class="-translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transition ease-in-out duration-300 transform"
            leave-class="translate-x-0"
            leave-to-class="-translate-x-full"
          >
            <div
              v-if="sidebarOpen"
              class="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800"
            >
              <div class="flex shrink-0 bg-gray-700">
                <div class="flex items-center">
                  <div class="ml-3 flex items-center justify-center">
                    <img
                      src="~assets/img/favicon-inverse.svg"
                      class="block h-16"
                      alt="Pilot Car Loads Logo"
                    >
                    <p
                      v-if="userData.isSuperuser"
                      class="text-xl font-medium capitalize leading-5 text-white"
                    >
                      Admin
                    </p>
                    <p
                      v-else-if="userData.type === 'driver'"
                      class="text-xl font-medium capitalize leading-5 text-white"
                    >
                      Driver / Escort
                    </p>
                    <p
                      v-else-if="userData.type === 'shipper'"
                      class="text-xl font-medium capitalize leading-5 text-white"
                    >
                      Load Manager
                    </p>
                  </div>
                </div>
              </div>

              <div class="absolute right-0 top-0 -mr-14 p-1">
                <button
                  class="flex size-12 items-center justify-center rounded-full focus:bg-gray-600 focus:outline-none"
                  aria-label="Close sidebar"
                  @click="sidebarOpen = false"
                >
                  <svg
                    class="size-6 text-white"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div
                class="h-0 flex-1 overflow-y-auto pb-4"
                role="navigation"
              >
                <dashboard-nav-superuser-nav v-if="userData.isSuperuser" />
                <dashboard-nav-shipper-nav v-else-if="userData.type === 'shipper'" />
                <dashboard-nav-driver-nav v-else />
              </div>

              <div class="flex shrink-0 bg-gray-700 p-4 pb-24">
                <div class="flex items-center">
                  <div class="ml-3">
                    <div class="pt-2 text-sm font-medium leading-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200">
                      {{ displayName }}
                    </div>

                    <p
                      v-if="userHasCompany"
                      class="pt-2 text-sm font-medium leading-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200"
                    >
                      {{ userData.company }}
                    </p>

                    <p class="pt-2 text-sm font-medium leading-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200">
                      {{ userData.email }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <div class="w-14 shrink-0">
            <!-- Force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </transition>
    </div>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:shrink-0">
      <div class="flex w-64 flex-col bg-gray-800">
        <div class="flex shrink-0 bg-gray-700">
          <div class="flex items-center">
            <div class="ml-3 flex items-center justify-center">
              <img
                src="~assets/img/favicon-inverse.svg"
                class="block h-16"
                alt="Pilot Car Loads Logo"
              >
              <p
                v-if="userData.isSuperuser"
                class="text-xl font-medium capitalize leading-5 text-white"
              >
                Admin
              </p>
              <p
                v-else-if="userData.type === 'driver'"
                class="text-xl font-medium capitalize leading-5 text-white"
              >
                Driver / Escort
              </p>
              <p
                v-else-if="userData.type === 'shipper'"
                class="text-xl font-medium capitalize leading-5 text-white"
              >
                Load Manager
              </p>
            </div>
          </div>
        </div>

        <div
          class="flex h-0 flex-1 flex-col overflow-y-auto pb-4"
          role="navigation"
        >
          <dashboard-nav-superuser-nav v-if="userData.isSuperuser" />
          <dashboard-nav-shipper-nav v-else-if="userData.type === 'shipper'" />
          <dashboard-nav-driver-nav v-else />
        </div>

        <div class="flex shrink-0 bg-gray-700 p-4 pb-28">
          <div class="flex items-center">
            <div class="ml-3">
              <div class="pt-2 text-sm font-medium leading-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200">
                {{ displayName }}
              </div>

              <p
                v-if="userHasCompany"
                class="pt-2 text-sm font-medium leading-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200"
              >
                {{ userData.company }}
              </p>

              <p
                id="email"
                class="pt-2 text-sm font-medium leading-4 text-gray-300 transition duration-150 ease-in-out group-hover:text-gray-200"
              >
                {{ userData.email }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex w-0 flex-1 flex-col overflow-hidden">
      <div class="bg-teal-700 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
        <button
          class="-ml-0.5 -mt-0.5 inline-flex h-12 w-24 cursor-pointer items-center justify-center rounded-md text-white transition duration-150 ease-in-out hover:text-gray-200 focus:bg-gray-200 focus:outline-none"
          aria-label="Open sidebar"
          @click="sidebarOpen = true"
        >
          <svg
            class="size-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          <span class="pl-2">Menu</span>
        </button>
      </div>

      <main
        id="main"
        role="main"
        class="relative flex-1 overflow-y-auto focus:outline-none"
        tabindex="-1"
      >
        <div
          id="dashboard"
          class="mx-auto max-w-7xl px-4 pb-6 pt-4 sm:px-6 md:px-8 md:py-6"
        >
          <dashboard-alerts-alert
            v-if="!loading && userData.type === 'driver' && isTrialing"
            class="mb-4"
            :alert-type="daysLeftInTrial <= 14 && !userData.hasPaymentMethod ? 'warning' : 'success'"
          >
            <span v-if="daysLeftInTrial > 14">Thanks for joining Pilot Car Loads! </span>Your free trial will end
            <span class="font-bold"> {{ daysLeftInTrial === 0 ? 'today' : `in ${daysLeftInTrialDisplay}` }}</span>.

            <span v-if="daysLeftInTrial <= 14 && !userData.hasPaymentMethod">Upgrade before your trial ends to keep getting Load Alerts!
              <nuxt-link
                class="font-bold underline"
                to="/dashboard/upgrade"
              >
                Learn&nbsp;More&nbsp;<span aria-hidden="true">&rarr;</span>
              </nuxt-link>
            </span>
            <span v-if="userData.hasPaymentMethod">You will be charged at the end of your trial. <nuxt-link
              class="font-bold underline"
              to="/dashboard/settings"
            >
              Downgrade&nbsp;to&nbsp;Free&nbsp;<span aria-hidden="true">&rarr;</span>
            </nuxt-link></span>
          </dashboard-alerts-alert>
          <dashboard-alerts-alert
            v-else-if="!loading && userData.type === 'driver' && isOnFreePlan"
            class="mb-4"
            alert-type="warning"
          >
            You aren't receiving Load Alerts because you are on the Basic Plan (free).
            Upgrade to start getting Load Alerts again! <nuxt-link
              class="font-bold underline"
              to="/dashboard/upgrade"
            >
              Learn&nbsp;More&nbsp;<span aria-hidden="true">&rarr;</span>
            </nuxt-link>
          </dashboard-alerts-alert>
          <dashboard-alerts-alert
            v-if="!loading && !alerts.length && userData.type === 'driver' && !isOnFreePlan"
            class="mb-4"
            alert-type="warning"
          >
            To begin receiving load notifications via text or email you need to set up an alert. <nuxt-link
              class="font-bold underline"
              to="/dashboard/alerts"
            >
              Set&nbsp;up&nbsp;an&nbsp;Alert&nbsp;<span aria-hidden="true">&rarr;</span>
            </nuxt-link>
          </dashboard-alerts-alert>
          <dashboard-alerts-alert
            v-else-if="!loading && userData.type === 'driver' && allAlertsInactive && !isOnFreePlan"
            class="mb-4"
            alert-type="warning"
          >
            You aren't receiving load notifications because all of your alerts are inactive. <nuxt-link
              class="font-bold underline"
              to="/dashboard/alerts"
            >
              <span class="underline">Activate&nbsp;an&nbsp;Alert</span>&nbsp;<span aria-hidden="true">&rarr;</span>
            </nuxt-link>
          </dashboard-alerts-alert>
          <dashboard-alerts-alert
            v-else-if="!loading && userData.type === 'driver' && !isOnFreePlan && noAlertMethodsSet"
            class="mb-4"
            alert-type="warning"
          >
            You aren't receiving Load Alerts because you have no notification methods set. <nuxt-link
              class="font-bold underline"
              to="/dashboard/alerts"
            >
              Set&nbsp;a&nbsp;notification&nbsp;method&nbsp;<span aria-hidden="true">&rarr;</span>
            </nuxt-link>
          </dashboard-alerts-alert>
          <nuxt-page />
        </div>
      </main>
    </div>
  </div>
</template>

<style lang="postcss">
input:disabled {
  @apply bg-gray-200 cursor-not-allowed;
}
</style>
