<script setup lang="ts">
interface Link {
  text: string;
  to: string;
  svg: string;
}

defineProps<{ links: Link[] }>();

const { showIntercom } = useIntercomStore();

const authStore = useAuthStore();
const { logOut } = authStore;
const { isApp } = storeToRefs(authStore);
</script>

<template>
  <nav class="mt-5 px-2">
    <nuxt-link
      v-for="link of links"
      :key="link.to"
      :target="link.to.startsWith('http') ? '_blank' : undefined"
      :external="link.to.startsWith('http')"
      :to="link.to"
      class="
        sidebar__link group flex cursor-pointer items-center rounded-md p-2 text-base font-medium leading-6 text-gray-500
        transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700
        focus:text-white focus:outline-none
      "
    >
      <div v-html="link.svg" />
      {{ link.text }}
    </nuxt-link>
    <div class="mx-4 mb-2 border-b border-gray-700 pt-2" />
    <nuxt-link
      v-if="!isApp"
      to="/dashboard/app"
      class="
        sidebar__link group flex cursor-pointer items-center rounded-md p-2 text-base font-medium leading-6 text-gray-500
        transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700
        focus:text-white focus:outline-none
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mr-2 size-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
        />
      </svg>
      Download App
    </nuxt-link>
    <a
      href="/dashboard/referral"
      class="
        sidebar__link group flex cursor-pointer items-center rounded-md p-2 text-base font-medium leading-6 text-gray-500
        transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700
        focus:text-white focus:outline-none
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mr-2 size-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
        />
      </svg>
      Refer a friend
    </a>
    <a
      href="http://help.pilotcarloads.com/en/collections/2714494-faqs"
      target="_blank"
      rel="noopener noreferrer"
      class="
        sidebar__link group flex cursor-pointer items-center rounded-md p-2 text-base font-medium leading-6 text-gray-500
        transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700
        focus:text-white focus:outline-none
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mr-2 size-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
      Help / FAQs
    </a>
    <button
      type="button"
      class="
        sidebar__link group flex w-full cursor-pointer items-center rounded-md p-2 text-base font-medium
        leading-6 text-gray-500 transition duration-150 ease-in-out hover:bg-gray-700
        hover:text-white focus:bg-gray-700 focus:text-white focus:outline-none
      "
      @click="showIntercom"
    >
      <svg
        class="mr-2 size-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512
              15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
        />
      </svg>
      Support Chat
    </button>
    <div class="mx-4 mb-2 border-b border-gray-700 pt-2" />
    <div
      class="
        sidebar__link group flex cursor-pointer items-center rounded-md p-2 text-base font-medium leading-6 text-gray-500
        transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700
        focus:text-white focus:outline-none
      "
      @click="logOut"
    >
      <svg
        class="mr-2 size-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
        />
      </svg>
      Log Out
    </div>
  </nav>
</template>

<style lang="postcss" scoped>
.nuxt-link-exact-active {
  @apply text-white bg-gray-900;
}

.sidebar__link:not(:first-child) {
  @apply mt-1;
}
</style>
